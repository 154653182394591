
//  production:  "https://api.spokenlayer.net/",
//  staging:     "https://api.staging.spokenlayer.net/",
//  development: "https://api.dev.spokenlayer.net/",
const API_URL = process.env.REACT_APP_API_URL || "https://api.spokenlayer.net/"

// production:  "197009364329348",
// staging:     "246719832791232",
// development: "246719832791232",
const FB_ID = process.env.REACT_APP_FB_ID || "197009364329348"

const SNOWPLOW_COLLECTOR = process.env.REACT_APP_SNOWPLOW_COLLECTOR || "d.spokenlayer.com"

const SNOWPLOW_COLLECTORS = ( process.env.NODE_ENV === "production" )
  ? { "production"  :  SNOWPLOW_COLLECTOR }
  : { "development" : SNOWPLOW_COLLECTOR }

const WEBPLAYER_URL = process.env.REACT_APP_WEBPLAYER_URL || "https://webplayer.spokenlayer.net/latest/webplayer.js"

export default {
  API_URL,
  FB_ID,
  SNOWPLOW_COLLECTORS,
  WEBPLAYER_URL
}
