
import styled from "styled-components"

const ShareIcon = styled.img`
  height: 1.5rem;
  max-width: 2.5rem;
  object-fit: contain;
`

export default ShareIcon
