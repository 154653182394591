import React from "react"
import PropTypes from "prop-types"
import Skeleton from "react-loading-skeleton"

import styled from "styled-components"
import Grid from "styled-components-grid"

import Analytics from "services/analytics"

import GridItem from "components/GridItem"
import SubscriptionLink from "components/SubscriptionLink"
import Typography from "components/Typography"

const onSubscriptionButtonClick = (label) => () => {
  Analytics.trackClickSubscribe({ label })
}

const SkeletonWrapper = styled.span`
  display: inline-block;
  max-width: 400px;
  width: 100%;
`

const SubscriptionsBlock = ({
  podcastData, publisher, story, isLoading,
}) => (
  <GridItem>
    <Typography size='display1' weight='bold' margin='bottom'>
      {isLoading ? <Skeleton wrapper={SkeletonWrapper} /> : "Subscribe on the Go"}
    </Typography>
    <Grid style={{ margin: " -0.625rem" }}>
      {podcastData.map((subscription, i) => (
        <SubscriptionLink
          key={i}
          onClick={onSubscriptionButtonClick(subscription.podcastProvider)}
          variant={subscription.podcastProvider}
          url={subscription.url}
        />
      ))}
    </Grid>
  </GridItem>
)

SubscriptionsBlock.propTypes = {
  podcastData: PropTypes.arrayOf(PropTypes.shape({
    podcastProvider: PropTypes.oneOf(["itunes", "spotify", "google", "iheartRadio", "overcast"]).isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
  publisher: PropTypes.object.isRequired,
  story: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
}

SubscriptionsBlock.defaultProps = {
  subscriptions: [],
}

export default SubscriptionsBlock
