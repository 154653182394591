
import React from 'react'
import { createGlobalStyle } from 'styled-components'
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'

import Analytics from 'services/analytics'
import Api from 'services/api'

import PublisherPage from 'pages/PublisherPage'

Analytics.initialize("snowplow_key")
Api.initialize()

const GlobalStyle = createGlobalStyle`
  body, html {
      font-size: 16px;
      margin: 0;
  }

  html {
    font-family: 'Lato', sans-serif;
  }

  a {
    text-decoration: none;
  }
`

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Switch>
        <Route exact path='/publishers/:publisherId/:episodeId' component={PublisherPage} />
        <Route exact path='/publishers/:publisherId' component={PublisherPage} />
        <Route render={ () => <p>hello world</p> } />
      </Switch>
    </Router>
  )
}

export default App
