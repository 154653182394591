import React from "react"
import PropTypes from "prop-types"

import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"

const Link = styled.a`
  ${breakpoint("mobile")`
    margin: 0.625rem;
  `};

  ${breakpoint("tablet")`
    margin: 0.9375rem;
  `};
`

const Image = styled.img``

const SubscriptionLink = ({ variant, url, onClick }) => {
  if ( variant === 'google' ) {
    const googleRegex = /^https?:\/\/podcasts\.google\.com/
    if (googleRegex.test(url)) {
      variant = 'google-podcasts'
    }
  }

  return (
    <Link href={url} target='_blank' onClick={onClick} rel='noopener noreferrer'>
      <Image src={`/imgs/${variant}.png`} />
    </Link>
  )
}

SubscriptionLink.propTypes = {
  variant: PropTypes.oneOf(["itunes", "spotify", "google", "iheartRadio", "overcast"]).isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default SubscriptionLink
