
import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

import Grid from "styled-components-grid"

import Api from "services/api"
import Analytics from "services/analytics"

import config from "config"

import Footer from "components/Footer"
import GridItem from "components/GridItem"
import PublisherCard from "components/PublisherCard"
import ResponsiveContainer from "components/ResponsiveContainer"
import SubscriptionsBlock from "components/SubscriptionsBlock"
import VirtualAssistantsBlock from "components/VirtualAssistantsBlock"

class PublisherPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      publisher: {},
      podcastData: [],
      error: null,
    }
  }

  componentDidMount() {
    const { publisherId } = this.props.match.params
    
    Analytics.trackSessionEvent("start", { distributorId: publisherId })

    Api.getPublisher(publisherId)
      .then((data) => {
        const podcastData =
          data.podcast && data.podcast.url
            ? Object.keys(data.podcast.url)
              .filter((podcastProvider) => { return data.podcast.url[podcastProvider] !== "" })
              .map((podcastProvider) => ({ podcastProvider, url: data.podcast.url[podcastProvider] }))
            : []

        const publisherThumbnail = data.display.thumbnail.default
          ? data.display.thumbnail.default.replace(
            /https:\/\/res.cloudinary.com\/spokenlayer\/image\/upload\/d_SpokenLayer_Logo_Verticle_fb9a1b.png/g,
            "https://res.cloudinary.com/spokenlayer/image/upload/ar_1:1,c_fill/c_scale,w_500,dpr_auto,fl_lossy,f_auto/d_SpokenLayer_Logo_Verticle_fb9a1b.png/v1",
          )
          : ""

        this.setState(
          {
            publisher: data,
            podcastData,
            publisherThumbnail,
          },
          () => {
            window.prerenderReady = true
          }
        )
      })
      .catch(
        (e) => {
          // TODO handle errors
          this.setState({
            error: e.toString(),
          })
        },
        () => {
          window.prerenderReady = true
        }
      )
  }

  render() {
    const {
      publisher, podcastData, error, publisherThumbnail,
    } = this.state

    const isLoading = !publisher.id

    // prevent "Listen to undefined" during loading
    const title = (publisher.name)
      ? `Listen to ${publisher.name}`
      : `Listen to `

    const hasVoiceAssistants = ( publisher.urls && (
      publisher.urls.customSkill || publisher.urls.flashBriefing ||
      publisher.urls.googleAction || publisher.urls.narrativeNews
    ))

    const hasPodcasts = podcastData.length > 0

    return (
      <ResponsiveContainer>
        {publisher && (
          <Helmet defer={false}>
            <title>{title}</title>
            <meta name='twitter:card' content='summary' />
            <meta property='og:url' content={`${window.location.href.split("?")[0]}`} />
            <meta property='og:title' content={title} />
            <meta property='og:description' content={ (publisher.description) ? publisher.description.info : ""} />
            <meta property='og:image' content={publisherThumbnail} />
            <meta property='og:image:width' content='1000' />
            <meta property='og:image:height' content='1000' />
            <meta property='og:type' content='website' />
            <meta property='fb:app_id' content={config.FB_ID} />
          </Helmet>
        )}
        <Grid>
        <GridItem horizontal={{ mobile: 2, tablet: 4, desktop: 4 }} top={{ mobile: 2, tablet: 4, desktop: 4 }}>
          <PublisherCard 
            publisher={publisher} 
            episodeId={this.props.match.params.episodeId} />

          {(hasVoiceAssistants || isLoading) && (
          <GridItem horizontal={{ mobile: 2, desktop: 3 }} top={{ mobile: 4 }} bottom={{mobile: 0}}>
            <VirtualAssistantsBlock invocations={publisher.invocationNames} urls={publisher.urls} isLoading={isLoading} />
          </GridItem>
          )}
          {(hasPodcasts || isLoading) && (
          <GridItem horizontal={{ mobile: 2, desktop: 3 }} vertical={{ mobile: 4 }} top={{ mobile: 4, tablet: 3 }}>
            <SubscriptionsBlock podcastData={podcastData} publisher={publisher} story={publisher} isLoading={isLoading} />
          </GridItem>
          )}
          {!hasPodcasts && <div style={{ marginBottom: 50 }} />}

        </GridItem>
        </Grid>

        <Footer />
        {error && <pre>{error}</pre>}
      </ResponsiveContainer>
    )
  }
}

PublisherPage.propTypes = {
  match: PropTypes.object.isRequired,
}

export default PublisherPage
