import React from "react"
import PropTypes from "prop-types"
import Skeleton from "react-loading-skeleton"

import styled from "styled-components"
import Grid from "styled-components-grid"

import Balloon from "components/Balloon"
import GridItem from "components/GridItem"
import Typography from "components/Typography"

import Analytics from "services/analytics"

import GOOGLE_ICON from "./google.png"
import ALEXA_ICON from "./alexa.png"

const Icon = styled.img`
  background-size: contain;
  height: 90px;
  width: 90px;
  min-height: 90px;
  min-width: 90px;
  margin-right: 22px;
`

const BalloonWrapper = styled.div`
  align-items: center;
  display: flex;
`

const SkeletonWrapper = styled.span`
  display: inline-block;
  max-width: 500px;
  width: 100%;
`

const Link = styled.a.attrs(props => ({
  target: "_blank",
  rel: "noopener noreferrer",
  onClick: () => { Analytics.trackClickSubscribe({label: props.label}) },
}))`
  color: #000000;
  text-decoration: ${props => props.underline ? "underline" : "none"};
`

class VirtualAssistantsBlock extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }
  
  render() {
    const { invocations, urls, isLoading } = this.props

    const hasAlexaSkill = urls && urls.customSkill
    const hasAlexaBriefing = urls && urls.flashBriefing

    const hasGoogleAction = urls && urls.googleAction
    const hasGoogleBriefing = urls && urls.narrativeNews

    const alexaInvocationPhrase = (hasAlexaSkill) 
      ? `Alexa, open ${invocations.alexa || ''}`
      : `Alexa, what's my Flash Briefing?`

    const googleInvocationPhrase = (hasGoogleAction)
      ? `Hey Google, talk to ${invocations.google || ''}`
      : `Hey Google, play the news from ${invocations.google || ''}`

    const alexaBriefingItem = (
      hasAlexaBriefing && (
      <Typography font='secondary' color='#000000' align='center' size='body1' margin='top'>
      Add to your Alexa Flash Briefing <Link href={urls.flashBriefing} label='alexa-briefing' underline={true}>HERE</Link>
      </Typography>
    ))
  
    const googleBriefingItem = (
      hasGoogleBriefing && (
      <Typography font='secondary' color='#000000' align='center' size='body1' margin='top'>
      Add to your Google News Briefing <Link href={urls.narrativeNews} label='google-briefing' underline={true}>HERE</Link>
      </Typography>
    ))

    const alexaSkillItem = (
      (hasAlexaSkill || hasAlexaBriefing) &&
      <Link 
        href={urls.customSkill || urls.flashBriefing} 
        label={ urls.customSkill ? 'alexa-skill' : 'alexa-action'}
        >
        <BalloonWrapper>
          <Icon src={ALEXA_ICON} />
          <Balloon>
            <Typography font='secondary' color='#000' align='center'>
              “{alexaInvocationPhrase}”
            </Typography>
          </Balloon>
        </BalloonWrapper>
      </Link>
    )

    const googleActionItem = (
      (hasGoogleAction || hasGoogleBriefing) &&
      <Link 
        href={ urls.googleAction || urls.narrativeNews } 
        label={ urls.googleAction ? 'google-action' : 'google-briefing' }>
        <BalloonWrapper>
          <Icon src={GOOGLE_ICON} />
          <Balloon>
            <Typography font='secondary' color='#000' align='center'>
              “{googleInvocationPhrase}”
            </Typography>
          </Balloon>
        </BalloonWrapper>
      </Link>
    )

    const alexaSkillBubble = (
      (alexaSkillItem || alexaBriefingItem) &&
      <GridItem
        size={{ mobile: 1, tablet: 1 / 2 }}
        bottom={{ mobile: 4, tablet: 1, desktop: 0 }}
        right={{ mobile: 0, tablet: 4 }}
        >
        {alexaSkillItem}
        {alexaBriefingItem}
      </GridItem>
    )

    const googleActionBubble = (
      (googleActionItem || googleBriefingItem) &&
      <GridItem 
        size={{ mobile: 1, tablet: 1 / 2 }}>
        {googleActionItem}
        {googleBriefingItem}
      </GridItem>
    )

    return (
      <GridItem>
      <Typography size='display1' weight='bold' margin='bottom'>
        {isLoading ? <Skeleton wrapper={SkeletonWrapper} /> : 'Ask Your Smart Speaker'}
      </Typography>

      <Grid>
        {alexaSkillBubble}
        {googleActionBubble}
      </Grid>
      </GridItem>
    )
  }
}

VirtualAssistantsBlock.propTypes = {
  invocations: PropTypes.shape({
    alexa: PropTypes.string,
    google: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
}

VirtualAssistantsBlock.defaultProps = {
  invocations: {
    alexa: '',
    google: '',
  },
  urls: {
  },
}

export default VirtualAssistantsBlock
