import React from "react"
import PropTypes from "prop-types"

import Grid from "styled-components-grid"
import Skeleton from "react-loading-skeleton"

import GridItem from "components/GridItem"
import Banner from "components/Banner"
import Typography from "components/Typography"
import ShareBlock from "components/ShareBlock"

import Analytics from "services/analytics"

class PublisherCard extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      currentTrack: {},
    }

    this.handleTrackChange = this.handleTrackChange.bind(this)
    this.handleAudioStart = this.handleAudioStart.bind(this)
  }

  handleTrackChange = (currentTrack) => {
    // console.log( currentTrack )
    this.setState(
        { currentTrack },
        () => { Analytics.trackTrackChange(currentTrack) },
    )
  }

  handleAudioStart = (currentTrack) => {
    Analytics.trackAudioStart(currentTrack)
  }

  getPublisherDescription () {
    const { publisher } = this.props

    if ( publisher.description && publisher.description.info ) {
      return publisher.description.info
    }

    return ""
  }

  getEpisodeDescription () {
    const { currentTrack } = this.state

    if ( currentTrack.content && currentTrack.content.summary ) {
      return currentTrack.content.summary
    }

    return this.getPublisherDescription()
  }

  getThumbnail () {
    const { publisher } = this.props

    const thumbnailExists = publisher.display && publisher.display.thumbnail && publisher.display.thumbnail.default

    return thumbnailExists
      ? publisher.display.thumbnail.default.replace(
        /https:\/\/res.cloudinary.com\/spokenlayer\/image\/upload\/d_SpokenLayer_Logo_Verticle_fb9a1b.png/g,
        "https://res.cloudinary.com/spokenlayer/image/upload/ar_1:1,c_fill/c_scale,w_500,dpr_auto,fl_lossy,f_auto/d_SpokenLayer_Logo_Verticle_fb9a1b.png/v1"
      )
      : ""
  }

  render() {
    const { publisher, episodeId } = this.props
    const { currentTrack } = this.state
    const Player = window.SpokenLayerPlayer

    const isLoading = !publisher.id

    const publisherThumbnail = this.getThumbnail()

    const playerGridItem = (
      <GridItem horizontal={{ mobile: 0 }} top={{ mobile: 1, tablet: 3 }} bottom={{ mobile: 1, tablet: 3 }} fullWidth>

      {currentTrack.content && (
      <GridItem bottom={{ mobile: 0 }}>
      <Typography 
        size='episodetitle' 
        align='center'
        font='secondary'
        >"{currentTrack.content.title}"</Typography>
      </GridItem>
      )}
      
      {publisher.id && Player && (
      <GridItem>
        <Player
          type='inline'
          iframe={false}
          variant='minimal'
          theme='light'
          border={false}
          share={false}
          publication={publisher.id}
          publisher={publisher}
          onAudioStart={this.handleAudioStart}
          onTrackChange={this.handleTrackChange}
          playlist={episodeId}
          continueWithPublicationPlaylist={true}
          appKey='reverb'
          />
      </GridItem>
      )}

      {!publisher.id && <Skeleton />}
      </GridItem>
    )

    return (
      <Grid>
        {/* desktop cover art */}
        <GridItem
          horizontal={{ desktop: 3 }}
          top={{ desktop: 3 }}
          size={{ desktop: 1 / 3 }}
          visible={{ mobile: false, desktop: true }}
          fullWidth
          >
          <Banner src={publisherThumbnail} />
        </GridItem>

        <GridItem top={{ mobile: 0 }} horizontal={{ desktop: 4 }} size={{ mobile: 1, desktop: 2/3 }}>

          {/* EPISODE CARD */}
          {episodeId && (
          <Grid>

            {/* mobile cover art and share buttons */}
            <GridItem 
              size={{ mobile: 1, tablet: 1/3, desktop: 1/2 }} 
              visible={{ mobile: true, desktop: false }} 
              bottom={{ mobile: 4, tablet: 0 }}
              horizontal={{ mobile: 2 }} 
              fullWidth>
              <Banner src={publisherThumbnail} />
            </GridItem>

            {/* show title and brand */}
            <GridItem
              size={{ mobile: 1, tablet: 2/3, desktop: 1 }} 
              bottom={{ mobile: 2 }} 
              horizontal={{ tablet: 3 }}>  
              <Typography size='display2' weight='bold' transform='uppercase' align='center'>
                {publisher.name || <Skeleton />}
              </Typography>
              <Typography size='display1' weight='light' margin='bottom' align='center'>
                {publisher.brand ? `by ${publisher.brand}` : ""}
              </Typography>
            </GridItem>

            {/* player */}
            {playerGridItem}

            {/* show description */}
            <GridItem horizontal={{ mobile: 2 }} top={{ mobile: 3 }} size={{ mobile: 1 }}>
              {this.getEpisodeDescription().split("\n").map((item, key) => (
                  <Typography size='episodesummary' key={key}>
                    {item}<br/>
                  </Typography>
                ))}
              {!publisher.description && <Skeleton count={5} />}
            </GridItem>
          </Grid>
          )}

          {/* PUBLISHER CARD */}
          {!episodeId && (
          <Grid>
            {/* show title and brand */}
            <GridItem horizontal={{ mobile: 2 }}>
              <Typography size='display2' weight='bold' transform='uppercase'>
                {publisher.name || <Skeleton />}
              </Typography>
              <Typography size='display1' weight='light' margin='bottom'>
                {publisher.brand ? `by ${publisher.brand}` : ""}
              </Typography>
            </GridItem>

            {/* mobile and tablet cover art */}
            <GridItem size={{ mobile: 1/2, tablet: 1/3 }}  horizontal={{ mobile: 2, tablet: 4 }} visible={{ desktop: false }} fullWidth>
              <Banner src={publisherThumbnail} />
              <GridItem top={{ mobile: 3 }} fullWidth>
                {isLoading ? <Skeleton /> : <ShareBlock publisher={publisher} story={currentTrack} type='mobile' />}
              </GridItem>
            </GridItem>

            {/* content */}
            <GridItem size={{ mobile: 1/2, tablet: 2/3, desktop: 1 }} horizontal={{ mobile: 2 }} bottom={{ mobile: 3 }} fullWidth>
 
              {/* show description */}
              {this.getPublisherDescription().split("\n").map((item, key) => (
                <Typography size='headline' key={key}>
                  {item}<br/>
                </Typography>
              ))} 
              {!publisher.description && <Skeleton count={5} />}
            </GridItem>
            
            {/* player */}
            {playerGridItem}

          </Grid>
          )}
          
        </GridItem>
      </Grid>
    )
  }
}

PublisherCard.propTypes = {
  publisher: PropTypes.shape({
    name: PropTypes.string,
    brand: PropTypes.string,
    description: PropTypes.shape({ info: PropTypes.string.isRequired }),
    display: PropTypes.object,
    invocationNames: PropTypes.object,
    urls: PropTypes.object,
  }).isRequired,
  podcastData: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string.isRequired, podcastProvider: PropTypes.string.isRequired }).isRequired),
}

PublisherCard.defaultProps = {
  podcastData: [],
  publisher: {
    invocationNames: {},
    urls: {},
  }
}

export default PublisherCard
