import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import breakpoint from "styled-components-breakpoint"

const FontSizeChooser = ({ size = "body1" }) => {
  switch (size.toLowerCase()) {
    
    case "display2":
      return "4rem"
    
    case "display1":
      return "2.25rem"

    case "headline":
      return "1.5rem"

    case "title":
      return "1.25rem"

    case "body2":
      return "1.125rem"

    case "body1":
      return "1rem"

    case "episodetitle":
      return "5rem"

    case "episodesummary":
      return "1rem"

    default:
      return "0.875em"
  }
}

const TabletFontSizeChooser = ({ size = "body1" }) => {
  switch (size.toLowerCase()) {
    
    case "display2":
      return "3rem"
    
    case "display1":
      return "2rem"

    case "headline":
      return "1.5rem"

    case "title":
      return "1.25rem"

    case "body2":
      return "1.125rem"

    case "body1":
      return "1rem"

    case "episodetitle":
      return "1.25rem"

    case "episodesummary":
      return "1rem"

    default:
      return "0.875em"
  }
}

const MobileFontSizeChooser = ({ size = "body1" }) => {
  switch (size.toLowerCase()) {
    
    case "display2":
      return "1.75rem"
    
    case "display1":
      return "1.25rem"

    case "headline":
      return "1.125rem"

    case "title":
      return "1.25rem"

    case "body2":
      return "1.125rem"

    case "body1":
      return "1rem"

    case "episodetitle":
      return "1.125rem"

    case "episodesummary":
      return "1rem"

    default:
      return "0.875em"
  }
}

const LineHeightChooser = ({ size = "body" }) => {
  switch (size.toLowerCase()) {
    
    case "display2":
      return " inherit"

    case "display1":
      return " inherit"

    case "episodesummary":
      return "1.5rem"

    default:
      return "1.8125rem"
  }
}

const MobileLineHeightChooser = ({ size = "body" }) => {
  switch (size.toLowerCase()) {
    
    case "display2":
      return " inherit"

    case "display1":
      return "1rem"

    case "episodesummary":
      return "1.5rem"

    default:
      return "1.3125rem"
  }
}

const FontWeightChooser = ({ weight = "normal" }) => {
  if (weight.toLowerCase() === "bold") {
    return "700"
  }

  if (weight.toLowerCase() === "light") {
    return "300"
  }

  return "400"
}

const TextTransformChooser = ({ transform = "normal" }) => {
  if (transform.toLowerCase() === "uppercase") {
    return "uppercase"
  }

  return "none"
}

const MarginChooser = ({ size = "body", margin = "none" }) => {
  const marginValue = size.indexOf("display") === 0 ? "0.6em" : "1em"

  if (margin.toLowerCase() === "both") {
    return `${marginValue} 0`
  }

  if (margin.toLowerCase() === "top") {
    return `${marginValue} 0 0 0`
  }

  if (margin.toLowerCase() === "bottom") {
    return `0 0 ${marginValue} 0`
  }

  return "0"
}

const AlignChooser = ({ align = "left" }) => align

const FontChooser = ({ font = "primary" }) => font === "secondary" ? "'Source Sans Pro'" : "'Lato'"

const ColorChooser = ({ color = "#4a4a4a" }) => color

const typographyCSS = css`
  color: ${ColorChooser};
  font-family: -apple-system, BlinkMacSystemFont, ${FontChooser}, sans-serif;
  font-weight: ${FontWeightChooser};
  margin: ${MarginChooser};
  padding: 0;
  text-align: ${AlignChooser};
  text-transform: ${TextTransformChooser};
  
  ${breakpoint("desktop")`
    font-size: ${FontSizeChooser};
    line-height: ${LineHeightChooser};
  `};

  ${breakpoint("tablet")`
    font-size: ${TabletFontSizeChooser};
  `};
  ${breakpoint("tablet")`
    line-height: ${LineHeightChooser};
  `};

  ${breakpoint("mobile")`
    font-size: ${MobileFontSizeChooser};
  `};
  ${breakpoint("mobile")`
    line-height: ${MobileLineHeightChooser};
  `};
`

const TypographyParagraph = styled.p`
  ${typographyCSS};
`

const TypographyInline = styled.span`
  ${typographyCSS};
`

const Typography = ({ variant, ...rest }) => {
  if (variant === "inline") {
    return <TypographyInline {...rest} />
  }
  return <TypographyParagraph {...rest} />
}

Typography.propTypes = {
  variant: PropTypes.oneOf(["inline", "paragraph"]),
}

Typography.defaultProps = {
  variant: "paragraph",
}

export default Typography
