
import styled from "styled-components"

const Balloon = styled.div`
  align-items: center;
  background: #fff;
  border: 2px solid #cccfd1;
  border-radius: 0.6875rem;
  display: flex;
  margin-left: 12px;
  max-width: 12rem;
  min-height: 3.75rem;
  padding: 10px;
  position: relative;

  &:before {
    content: "";
    border-color: transparent #cccfd1;
    border-style: solid;
    border-width: 10px 12px 10px 0;
    bottom: auto;
    display: block;
    left: -12px;
    position: absolute;
    top: 47px;
    width: 0;
  }

  &:after {
    border-color: transparent #fff;
    border-style: solid;
    border-width: 7px 10px 7px 0;
    bottom: auto;
    content: "";
    display: block;
    left: -10px;
    position: absolute;
    top: 50px;
    width: 0;
  }
`

export default Balloon
