
import axios from "axios"

import config from "config"

export default class Api {
  static instance = null

  static initialize = () => {
    this.instance = axios.create({
      baseURL: config.API_URL,
      timeout: 60000,
      // headers: { "X-Player-Key": key },
    })
  }

  static getPublisher = (publisherId) => this.instance
    .get(`/web-player/reverb?publisher=${publisherId}`)
    .then((r) => r.data.result)

  static getPublishers = () => this.instance
    .get("/public/publishers")
    .then((r) => r.data.result)

  static getPublisherStories = (publisherId) => this.instance
    .get(`/public/publishers/${publisherId}/stories`)
    .then((r) => r.data.result)

  static getPublisherRecentStory = (publisherId) => this.instance
    .get(`/public/publishers/${publisherId}/recent_story`).then((r) => r.data.result)

  static getStory = (storyId) => this.instance
    .get(`/public/stories/${storyId}`)
    .then((r) => r.data.result)
}
