import React from "react"
import PropTypes from "prop-types"

import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"

import Analytics from "services/analytics"

import ShareIcon from "components/ShareIcon"
import ShareIconDesktop from "components/ShareIconDesktop"
import GridItem from "components/GridItem"

const ShareContainer = styled.div`
  display: flex;

  ${breakpoint("mobile")`
        justify-content: space-around;
  `};

  ${breakpoint("tablet")`
        justify-content: center;

        & > * {
            margin-left: 1.5rem;
        }

        & > *:first-of-type {
            margin-left: 0rem;
        }
  `};
`

const ShareContainerDesktop = styled.div`
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 0 7px 7px 0;
  left: 0;
  padding: 5px 10px;
  position: fixed;
  top: 45px;
`

const getTrackableURL = (url, medium, publisherId, storyId, escape = false) => {
  const params = `utm_source=reverb&utm_medium=${medium}`
  if (escape) return `${url}?${encodeURIComponent(params)}`
  return `${url}?${params}`
}

const getPublicationText = (name) => encodeURIComponent(`Listen to the latest episodes${name ? ` from ${name}` : ""}.`)

const getPublicationTextWithURL = (name, url) => encodeURIComponent(`Listen to the latest episodes${name ? ` from ${name}` : ""} - ${url}.`)

const getTwitterLink = (url, publisher, story) =>
  `https://twitter.com/intent/tweet?text=${getPublicationText(publisher.name)}&url=${getTrackableURL(
    url,
    "twitter",
    publisher.id,
    story.id,
    true
  )}`

const getFacebookLink = (url, publisher, story) =>
  `https://www.facebook.com/sharer/sharer.php?u=${getTrackableURL(url, "facebook", publisher.id, story.id)}`

const getEmailLink = (url, publisher, story) => {
  const trackableUrl = getTrackableURL(url, "email", publisher.id, story.id)
  return `mailto:?to=&subject=${getPublicationText(publisher.name)}&body=${getPublicationTextWithURL(publisher.name, trackableUrl)}`
}

const getSmsLink = (url, publisher, story) => {
  const userAgent = navigator.userAgent.toLowerCase()
  const trackableUrl = getTrackableURL(url, "sms", publisher.id, story.id, true)

  if (userAgent.indexOf("iphone") > -1 || userAgent.indexOf("ipad") > -1) {
    return `sms:&body=${getPublicationTextWithURL(publisher.name, trackableUrl)}`
  }
  return `sms:?body=${getPublicationTextWithURL(publisher.name, trackableUrl)}`
}

const onShareButtonClick = (label) => () => {
  Analytics.trackClickShare({ label })
}

const ShareBlock = ({ publisher, story, type }) => {
  const url = publisher.shareUrl

  if (type === "mobile") {
    return (
      <GridItem style={{ marginTop: "auto" }} top={{ mobile: 2 }}>
        <ShareContainer>
          <a
            href={getFacebookLink(url, publisher, story)}
            target='_blank'
            rel='noopener noreferrer'
            onClick={onShareButtonClick("facebook")}
          >
            <ShareIcon src='/imgs/facebook.png' />
          </a>
          <a href={getTwitterLink(url, publisher, story)} target='_blank' rel='noopener noreferrer' onClick={onShareButtonClick("twitter")}>
            <ShareIcon src='/imgs/twitter.png' />
          </a>
          <a href={getEmailLink(url, publisher, story)} target='_blank' rel='noopener noreferrer' onClick={onShareButtonClick("email")}>
            <ShareIcon src='/imgs/mail.png' />
          </a>
          <a href={getSmsLink(url, publisher, story)} target='_blank' rel='noopener noreferrer' onClick={onShareButtonClick("sms")}>
            <ShareIcon src='/imgs/sms.png' />
          </a>
        </ShareContainer>
      </GridItem>
    )
  }

  return (
    <GridItem style={{ marginTop: "auto" }} top={{ mobile: 2 }}>
      <ShareContainerDesktop>
        <ShareIconDesktop
          type='facebook'
          href={getFacebookLink(url, publisher, story)}
          target='_blank'
          rel='noopener noreferrer'
          onClick={onShareButtonClick("facebook")}
        />

        <ShareIconDesktop
          type='twitter'
          href={getTwitterLink(url, publisher, story)}
          target='_blank'
          rel='noopener noreferrer'
          onClick={onShareButtonClick("twitter")}
        />

        <ShareIconDesktop
          type='mail'
          href={getEmailLink(url, publisher, story)}
          target='_blank'
          rel='noopener noreferrer'
          onClick={onShareButtonClick("email")}
        />

        <ShareIconDesktop
          type='sms'
          href={getSmsLink(url, publisher, story)}
          target='_blank'
          rel='noopener noreferrer'
          onClick={onShareButtonClick("sms")}
        />
      </ShareContainerDesktop>
    </GridItem>
  )
}

ShareBlock.propTypes = {
  type: PropTypes.oneOf(["mobile", "desktop"]),
  publisher: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }),
  story: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
}

ShareBlock.defaultProps = {
  type: "mobile",
  publisher: {
    name: "",
    id: "",
  },
  story: {
    id: "",
  },
}

export default ShareBlock
