import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const BannerWrapper = styled.div`
  height: 0;
  padding-top: 100%;
  position: relative;
  width: 100%;
`

const BannerImg = styled.img`
  background-color: #eee;
  bottom: 0;
  border-radius: 0.5rem;
  box-shadow: ${({ src }) => src ? "0 4px 10px 0 rgba(0,0,0,0.50)" : ""};
  left: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`

const Banner = ({ src }) => (
  <BannerWrapper>
    <BannerImg src={src} />
  </BannerWrapper>
)

Banner.propTypes = {
  src: PropTypes.string.isRequired,
}

export default Banner
