import React from "react"
import PropTypes from "prop-types"

import styled from "styled-components"

const Icon = styled.a`
  background-size: contain;
  cursor: pointer;
  display: block;
  padding: 5px 0;
  min-height: 44px;
  min-width: 44px;
  height: 44px;
  width: 44px;
  text-decoration: none;
`

const renderIcon = {
  facebook: () => (
    <svg viewBox='0 0 44 44' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Share-Pop-Up-Colour' transform='translate(-11.000000, -11.000000)'>
          <g id='Share-Pop-Up-Copy'>
            <g id='Facebook-Icon-Colour' transform='translate(11.000000, 11.000000)'>
              <rect id='Mask' fill='#4267B2' x='0' y='0' width='44' height='44' rx='4' />
              <path
                d='M30.36,44 L30.36,26.9852 L36.08,26.9852 L36.96,20.3236 L30.36,20.3236 L30.36,16.082 C30.36,14.1592 30.8968,12.848 33.6556,12.848 L37.1492,12.848 L37.1492,6.908 C35.4482357,6.72485152 33.7383804,6.63671465 32.0276,6.644 C26.9588,6.644 23.4872,9.724 23.4872,15.422 L23.4872,20.3236 L17.7672,20.3236 L17.7672,26.9852 L23.4872,26.9852 L23.4872,44 L30.36,44 Z'
                id='f'
                fill='#fff'
                fillRule='nonzero'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  mail: () => (
    <svg viewBox='0 0 44 44' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Share-Pop-Up-Colour' transform='translate(-139.000000, -10.000000)'>
          <g id='Share-Pop-Up-Copy'>
            <g id='Mail-Icon-Colour' transform='translate(139.000000, 10.000000)'>
              <rect id='Rectangle-6-Copy-2' fill='#DC4D40' x='0' y='0' width='44' height='44' rx='4' />
              <path
                d='M39,19.0062167 C39,18.4504986 38.6393236,18.258918 38.1869474,18.5836813 C38.1869474,18.5836813 30.7466518,23.9245283 27.6540179,26.1509434 C26.0602679,27.3207547 24.0870536,28.754717 22.0189732,28.754717 C19.9129464,28.754717 17.9397321,27.3207547 16.3459821,26.1509434 C13.2533482,23.9056604 5.81305259,18.5820044 5.81305259,18.5820044 C5.36401604,18.2605723 5,18.4483555 5,19.0062167 L5,32.9811321 C5,34.6415094 6.36607143,36 8.03571429,36 L35.9642857,36 C37.6339286,36 39,34.6415094 39,32.9811321 L39,19.0062167 Z'
                id='Path'
                fill='#fff'
              />
              <path
                d='M35.9643226,9 L8.0357179,9 C6.00558156,9 5,9.21354167 5,12.4531291 C5,14.1796937 5.06313987,15.0708309 8.26339675,17.2343848 C11.2042485,19.2834944 14.1830467,21.5419025 17.1238984,23.6099854 C18.3571588,24.463781 20.4442148,26.0000203 21.981047,26.0000203 C23.5558257,26.0000203 25.7503286,24.4588738 26.983589,23.6050781 C29.9244407,21.5369953 32.7962922,19.392067 35.755617,17.2343848 C38.7948435,15.0184453 39.0000405,14.1607204 39.0000405,12.0357179 C39.0000405,9.11816406 37.6149942,9 35.9643226,9 Z'
                id='Path'
                fill='#fff'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  twitter: () => (
    <svg viewBox='0 0 44 44' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Share-Pop-Up-Colour' transform='translate(-75.000000, -11.000000)'>
          <g id='Share-Pop-Up-Copy'>
            <g id='Twitter-Icon-Colour' transform='translate(75.000000, 11.000000)'>
              <rect id='Mask' fill='#1AA1F1' x='0' y='0' width='44' height='44' rx='4' />
              <path
                d='M16.7955182,33 C27.3613445,33 33.1428571,24.5334646 33.1428571,17.2037402 C33.1428571,16.9655512 33.1428571,16.7273622 33.1316527,16.4891732 C34.2521008,15.7096457 35.2268908,14.7244094 36,13.609252 C34.9691877,14.0531496 33.859944,14.3454724 32.6946779,14.4862205 C33.8823529,13.8041339 34.789916,12.7106299 35.2268908,11.4114173 C34.1176471,12.0501969 32.8851541,12.5049213 31.5742297,12.753937 C30.5210084,11.6712598 29.0308123,11 27.3837535,11 C24.2128852,11 21.6358543,13.4901575 21.6358543,16.5541339 C21.6358543,16.9872047 21.6918768,17.4094488 21.7815126,17.8208661 C17.0084034,17.5935039 12.7731092,15.3740157 9.93837535,12.0177165 C9.44537815,12.8405512 9.16526611,13.7933071 9.16526611,14.8110236 C9.16526611,16.738189 10.1848739,18.4379921 11.719888,19.4340551 C10.7787115,19.4015748 9.89355742,19.1525591 9.12044818,18.7411417 C9.12044818,18.7627953 9.12044818,18.7844488 9.12044818,18.8169291 C9.12044818,21.5019685 11.1036415,23.753937 13.7254902,24.2627953 C13.2436975,24.3927165 12.7394958,24.4576772 12.2128852,24.4576772 C11.8431373,24.4576772 11.4845938,24.4251969 11.1372549,24.3602362 C11.8655462,26.5688976 13.9943978,28.1712598 16.5042017,28.2145669 C14.5322129,29.7086614 12.0560224,30.5964567 9.36694678,30.5964567 C8.90756303,30.5964567 8.44817927,30.5748031 8,30.5206693 C10.5322129,32.0797244 13.557423,33 16.7955182,33'
                id='Shape'
                fill='#fff'
                fillRule='nonzero'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  sms: () => (
    <svg viewBox='0 0 44 44' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Share-Pop-Up-Colour' transform='translate(-203.000000, -11.000000)'>
          <g id='Share-Pop-Up-Copy'>
            <g id='SMS-Icon-Colour' transform='translate(203.000000, 11.000000)'>
              <rect id='Rectangle-6-Copy-3' fill='#32CD2C' x='0' y='0' width='44' height='44' rx='4' />
              <path
                d='M39.0000393,19.6666806 C39.0000393,13.2135479 31.6155439,8 22.5000197,8 C13.3844954,8 6,13.2317771 6,19.6666806 C6,23.3672058 8.43080647,26.6666889 12.2243378,28.799504 C11.3588234,31.8437784 10.0329289,32.9375297 8.87277128,34.2318021 C8.59654327,34.5599275 8.28348486,34.8333653 8.37556087,35.289095 C8.37556087,35.289095 8.37556087,35.289095 8.37556087,35.3073242 C8.46763687,35.7448247 8.85435608,36.0364918 9.25949049,36.0000334 C9.97768331,35.9088874 10.6958761,35.7812831 11.3588234,35.5989912 C14.544653,34.7969069 17.4174243,33.3021135 19.8298156,31.1875276 C20.69533,31.2786736 21.5976748,31.3333611 22.5000197,31.3333611 C31.6155439,31.3333611 39.0000393,26.1198133 39.0000393,19.6666806 L39.0000393,19.6666806 Z'
                id='SMS'
                fill='#fff'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
}

const ShareIcon = ({ type, ...rest }) => <Icon {...rest}>{renderIcon[type]()}</Icon>

ShareIcon.propTypes = {
  type: PropTypes.oneOf(["facebook", "twitter", "sms", "mail"]),
}

export default ShareIcon
