
import TagManager from 'react-gtm-module'

// google tag manager
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_PREVIEW
}

export default class Analytics {

  static initialize = (key) => {
    this.key = key
    this.trackerData = {
      trackerNamespace: "reverb",
      appId: key,
      platform: "web",
      userId: "test_user_id",
      language: "en",
    }
    this.commonData = {}
    this.currentTrack = {}

    // init google tag manager
    TagManager.initialize(tagManagerArgs)
  }

  static _track = (schema, event, data = {}) => {
    window.reverbSnowplowTracker("trackUnstructEvent", {
      schema: `iglu:com.spokenlayer/${schema}/jsonschema/1-0-0`,
      data: {
        tracker: this.trackerData,
        common: this.commonData,
        eventData: {
          eventName: event,
          ...data,
        },
      },
    })
  }

  static trackTrackChange(data = {}) {
    this.currentTrack = data
  }

  static trackSessionEvent(event, data = {}) {
    this._track("session", event, data)
  }

  static trackStoryEvent(event, data = {}) {
    this._track("story", event, data)

  }

  static trackAudioStart(data = {}) {
    window.dataLayer.push({
      event: 'audioStart',
      audioStartId: `${data.publisherId}-${data.id}`
    })
  }

  static trackClickSubscribe(data = {}) {
    const ct = this.currentTrack

    window.dataLayer.push({
      event: 'clickSubscribe',
      clickSubscribeLabel: `${ct.publisherId}-${ct.id}-${data.label}`,
    })

    this.trackStoryEvent(
      'subscribe',
      {
        subscriptionService: data.label,
        distributorId: ct.publisherId,
        storyId: ct.id,
      }
    )
  }

  static trackClickShare(data = {}) {
    const ct = this.currentTrack

    window.dataLayer.push({
      event: 'clickShare',
      clickShareLabel: `${ct.publisherId}-${ct.id}-${data.label}`,
    })

    this.trackStoryEvent(
      'share', 
      { 
        sharingService: data.label,
        distributorId: ct.publisherId,
        storyId: ct.id,
      }
    )
  }

}
