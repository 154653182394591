import React from "react"
import PropTypes from "prop-types"

import Grid from "styled-components-grid"
import { Padding } from "styled-components-spacing"

const GridItem = ({
  size, horizontal, vertical, top, bottom, right, children, fullWidth, ...rest
}) => (
  <Grid.Unit size={size} {...rest}>
    <Padding
      style={fullWidth ? { boxSizing: "border-box", width: "100%" } : {}}
      horizontal={horizontal}
      vertical={vertical}
      top={top}
      bottom={bottom}
      right={right}
    >
      {children}
    </Padding>
  </Grid.Unit>
)

GridItem.propTypes = {
  size: PropTypes.object,
  horizontal: PropTypes.object,
  vertical: PropTypes.object,
  top: PropTypes.object,
  bottom: PropTypes.object,
  right: PropTypes.object,
  children: PropTypes.any,
  fullWidth: PropTypes.bool,
}

export default GridItem
