import React from "react"
import styled from "styled-components"

import GridItem from "components/GridItem"
import Typography from "components/Typography"

const Link = styled.a`
  color: inherit;
  text-decoration: none;
`

const Footer = () => (
  <GridItem vertical={{ mobile: 2 }} bottom={{ mobile: 4 }}>
    <Typography align='center' size='title' style={{ opacity: 0.6 }}>
      powered by{" "}
      <Typography size='title' variant='inline' weight='bold'>
        <Link href='https://www.spokenlayer.com/' target='_blank' rel='noopener noreferrer'>
          spokenlayer.com
        </Link>
      </Typography>
    </Typography>
  </GridItem>
)

export default Footer
